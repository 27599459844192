.rounded-full {
  border-radius: 9999px;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.circle-60 {
  width: 60px !important;
  height: 60px !important;
  max-width: 60px !important;
  max-height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-50 {
  width: 50px !important;
  height: 50px !important;
  max-width: 50px !important;
  max-height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-0 {
  border-width: 0;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-x-2 {
  border-left-width: 2px;
  border-right-width: 2px;
  border-top-width: 0;
  border-bottom-width: 0;
}

.border-blue-darkest {
  border-color: var(--blue-darkest);
  border-style: solid;
}

.border-blue-dark {
  border-color: var(--blue-dark);
  border-style: solid;
}

.border-blue-medium {
  border-color: var(--blue-medium);
  border-style: solid;
}

.border-blue {
  border-color: var(--blue);
  border-style: solid;
}

.border-blue-light {
  border-color: var(--blue-light);
  border-style: solid;
}

.border-gray-darkest {
  border-color: var(--gray-darkest);
  border-style: solid;
}

.border-gray-dark {
  border-color: var(--gray-dark);
  border-style: solid;
}

.border-gray {
  border-color: var(--gray);
  border-style: solid;
}

.border-gray-medium {
  border-color: var(--gray-medium);
  border-style: solid;
}

.border-gray-light {
  border-color: var(--gray-light);
  border-style: solid;
}

.border-gray-lightest {
  border-color: var(--gray-lightest);
  border-style: solid;
}

.border-purple-dark {
  border-color: var(--purple-dark);
  border-style: solid;
}

.border-purple-medium {
  border-color: var(--purple-medium);
  border-style: solid;
}

.border-purple {
  border-color: var(--purple);
  border-style: solid;
}

.border-magenta {
  border-color: var(--magenta);
  border-style: solid;
}

.border-green {
  border-color: var(--green);
  border-style: solid;
}

.border-green-dark {
  border-color: var(--green-dark);
  border-style: solid;
}

.border-white {
  border-color: var(--white);
  border-style: solid;
}

.border-black {
  border-color: var(--black);
  border-style: solid;
}

/* Text sizes */
.titleBig {
  font-size: 45px;
}

.titleMedium {
  font-size: 35px;
}

.titleSmall {
  font-size: 30px;
}

.textBig {
  font-size: 25px;
  line-height: 1.3;
}

.textMedium {
  font-size: 20px;
  line-height: 1.25;
}

.textRegular {
  font-size: 17px;
}

.textSmall {
  font-size: 14px;
}
.textSmaller {
  font-size: 11px !important;
}

/* Direct span child modifier */

.span-blue-darkest > span,
.span-blue-darkest > strong {
  color: var(--blue-darkest);
}
.span-blue-dark > span {
  color: var(--blue-dark);
}
.span-blue-medium > span {
  color: var(--blue-medium);
}
.span-blue > span {
  color: var(--blue);
}
.span-blue-light > span {
  color: var(--blue-light);
}
.span-gray-darkest > span {
  color: var(--gray-darkest);
}
.span-gray-dark > span {
  color: var(--gray-dark);
}
.span-gray > span {
  color: var(--gray);
}
.span-gray-medium > span {
  color: var(--gray-medium);
}
.span-gray-light > span {
  color: var(--gray-light);
}
.span-gray-lightest > span {
  color: var(--gray-lightest);
}
.span-purple-dark > span {
  color: var(--purple-dark);
}
.span-purple-medium > span {
  color: var(--purple-medium);
}
.span-purple > span {
  color: var(--purple);
}
.span-magenta > span {
  color: var(--magenta);
}
.span-green > span {
  color: var(--green);
}
.span-green-dark > span {
  color: var(--green-dark);
}
.span-white > span {
  color: var(--white);
}
.span-black > span {
  color: var(--black);
}

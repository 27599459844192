/* color */
.bg-blue-darkest {
  background: var(--blue-darkest);
}

.bg-blue-dark {
  background: var(--blue-dark);
}

.bg-blue-medium {
  background: var(--blue-medium);
}

.bg-blue {
  background: var(--blue);
}

.bg-blue-light {
  background: var(--blue-light);
}

.bg-gray-darkest {
  background: var(--gray-darkest);
}

.bg-gray-dark {
  background: var(--gray-dark);
}

.bg-gray {
  background: var(--gray);
}

.bg-gray-medium {
  background: var(--gray-medium);
}

.bg-gray-light {
  background: var(--gray-light);
}

.bg-gray-lightest {
  background: var(--gray-lightest);
}

.bg-purple-dark {
  background: var(--purple-dark);
}

.bg-purple-medium {
  background: var(--purple-medium);
}

.bg-purple {
  background: var(--purple);
}

.bg-magenta {
  background: var(--magenta);
}

.bg-green {
  background: var(--green);
}

.bg-green-dark {
  background: var(--green-dark);
}

.bg-white {
  background: var(--white);
}

.bg-black {
  background: var(--black);
}

.bg-gradient-blue {
  background: rgb(24, 68, 105);
  background: linear-gradient(90deg, rgba(24, 68, 105, 1) 0%, rgba(56, 108, 154, 1) 100%);
}

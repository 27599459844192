@use "./settings/sizes";
@use "./settings/spacing";
@import "utilities";

@tailwind base;
@tailwind components;
@tailwind utilities;

// @font-face {
//   font-family: "Araboto";
//   src: url("/assets/fonts/Araboto/Araboto Black 400.ttf");
//   font-weight: 900;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Araboto";
//   src: url("/assets/fonts/Araboto/Araboto Bold 400.ttf");
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Araboto";
//   src: url("/assets/fonts/Araboto/Araboto Light 400.ttf");
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Araboto";
//   src: url("/assets/fonts/Araboto/Araboto Medium 400.ttf");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Araboto";
//   src: url("/assets/fonts/Araboto/Araboto Normal 400.ttf");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Araboto";
//   src: url("/assets/fonts/Araboto/Araboto Thin 400.ttf");
//   font-weight: 100;
//   font-style: normal;
// }

// @font-face {
//   font-family: "roboto";
//   src: url("/assets/fonts/roboto/Roboto-Black.ttf");
//   font-weight: 900;
//   font-style: normal;
// }

// @font-face {
//   font-family: "roboto";
//   src: url("/assets/fonts/roboto/Roboto-Bold.ttf");
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: "roboto";
//   src: url("/assets/fonts/roboto/Roboto-Light.ttf");
//   font-weight: 300;
//   font-style: normal;
// }

// @font-face {
//   font-family: "roboto";
//   src: url("/assets/fonts/roboto/Roboto-Medium.ttf");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "roboto";
//   src: url("/assets/fonts/roboto/Roboto-Regular.ttf");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "roboto";
//   src: url("/assets/fonts/roboto/Roboto-Thin.ttf");
//   font-weight: 100;
//   font-style: normal;
// }

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@font-face {
  font-family: "Global-Lynx";
  src: url("/assets/fonts/GlobalLynx/Global Linx.ttf");
  font-weight: 700;
  font-style: normal;
}

/* Color guide */
:root {
  /* Original base Colors */
  // --blue-darkest: #0e3b5e;
  // --blue-dark: #005fab;
  // --blue-medium: #1f71b8;
  // --blue: #0090df;
  // --blue-light: #5ac0ee;
  /* Colors */
  --blue-darkest: #0e3b5e;
  --blue-dark: #005fab;
  --blue-medium: #1f71b8;
  --blue: #0090df;
  --blue-light: #5ac0ee;
  --blue-lightest: #98d6f9;

  --gray-darkest: #242424;
  --gray-dark: #605f5f;
  --gray: #a4a196;
  --gray-medium: #e6e6e6;
  --gray-light: #f6f6f6;
  --gray-lightest: #f9fafc;

  --purple-dark: #3c3484;
  --purple-medium: #6c3483;
  --purple: #a0287d;
  --magenta: #c7005a;
  --green: #bcce00;
  --green-dark: #9eac00;
  --white: #ffffff;
  --black: #000000;
  --error: rgb(223, 43, 11);
  /* Box shadow */
  --box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

  /* Font sizes */
  --title-big: 48px;
  --title-medium: 34px;
  --title-small: 30px;
  --text-big: 25px;
  --text-medium: 19px;
  --text-regular: 17px;
  --text-small: 14px;
  --text-smaller: 12px;

  /* Container component */
  --container-width: #{sizes.$w-container};
  --container-max-width: #{max-w-container};

  @media (min-width: sizes.$mobile) {
    --container-max-width: #{sizes.$max-w-container};
  }

  @media (min-width: sizes.$tablet) {
    --container-max-width: #{sizes.$max-w-container};
  }

  @media (min-width: sizes.$desktop) {
    --container-max-width: #{sizes.$max-w-container};
  }

  /* Big Container component */
  --big-container-width: #{sizes.$w-big-container};
  --big-container-max-width: #{sizes.$w-big-container};

  @media (min-width: sizes.$mobile) {
    --big-container-max-width: #{sizes.$w-big-container};
  }

  @media (min-width: sizes.$tablet) {
    --big-container-max-width: #{sizes.$w-big-container};
  }

  @media (min-width: sizes.$desktop) {
    --big-container-max-width: #{sizes.$max-w-big-container};
  }

  /* Blog Article Container component */
  --blog-article-container-width: #{sizes.$w-blog-article-container};
  --blog-article-container-max-width: #{sizes.$max-w-blog-article-container};

  @media (min-width: sizes.$mobile) {
    --blog-article-container-max-width: #{sizes.$max-w-blog-article-container};
  }

  @media (min-width: sizes.$tablet) {
    --blog-article-container-max-width: #{sizes.$max-w-blog-article-container};
  }

  @media (min-width: sizes.$desktop) {
    --blog-article-container-max-width: #{sizes.$max-w-blog-article-container};
  }

  /* Button component */
  --button-height: 50px;
  --button-border-radius: 5px;

  /* Cards */
  --card-padding: 30px;
  --card-border-radius: 5px;
  --card-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

  /* Inputs */
  --input-border-radius: 5px;

  /* Select */
  --react-international-phone-height: 44px;
  --react-international-phone-background-color: var(--white);
  --react-international-phone-text-color: var(--gray-darkest);
  --react-international-phone-font-size: var(--text-small);
  --react-international-phone-border-radius: var(--input-border-radius);
  --react-international-phone-border-color: var(--gray-medium);
  --react-international-phone-disabled-background-color: whitesmoke;
  --react-international-phone-disabled-text-color: #666;
}

/* Reseting tailwind */
@layer base {
  * {
    // font-family: 'Roboto Condensed', sans-serif;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::selection {
    background: var(--blue);
    color: var(--white);
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  * {
    scroll-behavior: smooth;
  }
  html {
    scroll-behavior: smooth;
    color: var(--gray-darkest);
  }
  a {
    text-decoration: none;
    cursor: pointer;
  }
  p {
    line-height: 1.25;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin: 0;
  }
  input,
  textarea,
  select {
    border: 1px solid var(--gray-medium);
    outline: none;
    height: 44px;
    color: var(--gray-darkest);
  }
  textarea {
    height: 60px;
  }

  input[type="checkbox"] {
    width: 12px;
    height: 12px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    &:not(:checked) {
      opacity: 0.4;
    }
    &:checked {
      opacity: 1;
    }
  }

  fieldset {
    border: 1px solid var(--gray-dark);
  }

  ::placeholder {
    color: var(--gray-medium);
  }
}

/* Components */
@layer components {
  .loading-effect-animation {
    z-index: 1;
    position: relative;
    overflow: hidden;
  }
  .loading-effect-animation::after {
    content: "";
    position: absolute;
    top: 0;
    left: -500px;
    width: 1000px;
    height: 100%;
    background: linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    animation-name: loading-effect;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: inherit;
  }
  @keyframes loading-effect {
    0% {
      left: -500px;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .big-title {
    font-size: var(--title-big);
  }
  .medium-title {
    font-size: var(--title-medium);
  }
  .small-title {
    font-size: var(--title-small);
  }
  .big-text {
    font-size: var(--text-big);
  }
  .medium-text {
    font-size: var(--text-medium);
  }
  .regular-text {
    font-size: var(--text-regular);
  }
  .small-text {
    font-size: var(--text-small);
  }
  .smaller-text {
    font-size: var(--text-smaller);
  }
  .child-span-blue span,
  .child-span-blue strong {
    color: var(--blue);
  }
  .child-span-blue-dark span,
  .child-span-blue-dark strong {
    color: var(--blue-dark);
  }
  .child-span-blue-darkest span,
  .child-span-blue-darkest strong {
    color: var(--blue-darkest);
  }
  .child-span-blue-light > span {
    color: var(--blue-light);
    font-weight: 800;
  }

  // 'blue-underline': 'decoration-blue-underline',
  // 'center-blue-underline': 'decoration-center-blue-underline',
  // 'dark-blue-pill': 'decoration-dark-blue-pill',
  // 'center-dark-blue-pill': 'decoration-center-dark-blue-pill'
  .decoration-blue-underline {
    position: relative;
    left: 0;
    width: 300px;
    max-width: 90% !important;
    border: none;
    border-top: 6px solid var(--blue-light);
    display: flex;
    align-items: center;
    margin: 1rem 0px;
    // Before element will be a dot at end of the after line
    &::before {
      content: "";
      position: absolute;
      left: calc(100% + 5px);
      transform: translateY(-3px);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--blue-light);
    }
  }
  .decoration-center-blue-underline {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius: 1000px;
    background-color: var(--blue-light);
    margin: 1rem auto;
    // Before element will be a dot at center of the after line
    &::before {
      content: "";
      position: absolute;
      width: 140px;
      height: 6px;
      right: calc(100% + 5px);
      background: var(--blue-light);
    }
    &::after {
      content: "";
      position: absolute;
      width: 140px;
      height: 6px;
      left: calc(100% + 5px);
      background: var(--blue-light);
    }
  }
  .blue-line {
    position: relative;
    min-height: 6px;
    width: 300px;
    background-color: var(--blue-light);
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      border: 1px solid transparent;
      left: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--blue-light);
    }
  }
  .decoration-dark-blue-pill {
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      width: 300%;
      height: calc(100% + 10px);
      right: -50px;
      top: -5px;
      background-color: var(--blue-darkest);
      border-radius: 1000px;
      z-index: -1;
    }
  }
  .decoration-center-dark-blue-pill {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    &::before {
      content: "";
      position: absolute;
      width: 300%;
      height: calc(100% + 10px);
      right: -50px;
      top: -5px;
      background-color: var(--blue-darkest);
      border-radius: 1000px;
      z-index: -1;
    }
  }

  .bg-blue-dark-degree-banner {
    background: linear-gradient(to top, rgb(2, 24, 40, 0.9) 0%, rgb(13, 56, 88, 0.1) 100%);
  }
  .bg-white-to-gray {
    background: linear-gradient(to right, #fff 50%, var(--gray-light) 50%);
  }
  .bg-gray-to-white {
    background: linear-gradient(to right, var(--gray-light) 50%, #fff 50%);
  }
  .bg-gray-degree {
    background: rgb(249, 250, 252);
    background: linear-gradient(180deg, rgba(249, 250, 252, 1) 0%, rgba(255, 255, 255, 1) 100%);
  }
  .bg-blue-degree {
    background: rgb(21, 60, 93);
    background: linear-gradient(0deg, rgba(21, 60, 93, 1) 0%, rgba(37, 95, 146, 1) 100%);
  }
  .bg-blue-medium-degree {
    background: rgb(6, 79, 120);
    background: linear-gradient(0deg, rgba(6, 79, 120, 1) 0%, rgba(8, 102, 147, 1) 100%);
  }
  .bg-blue-light-degree {
    background: rgb(8, 102, 147);
    background: linear-gradient(0deg, rgba(8, 102, 147, 1) 0%, rgba(57, 141, 190, 1) 100%);
  }
  .bg-blue-dark-degree {
    background: #0c3554;
    background: linear-gradient(to top, #031b2c 10%, #0c3554 100%);
  }

  .bg-blue-darkest-to-b {
    background: #0d3858;
    background: linear-gradient(to bottom, #021828 10%, #0d3858 100%);
  }
  .bg-blue-darkest-to-t {
    background: #0d3858;
    background: linear-gradient(to top, #021828 10%, #0d3858 100%);
  }
  .bg-dark-gray-to-b {
    background: #4a4a4a;
    background: linear-gradient(to bottom, #272727 33%, #4b4b4b 100%);
  }
  .bg-dark-gray-to-t {
    background: #4a4a4a;
    background: linear-gradient(to top, #272727 33%, #4b4b4b 100%);
  }

  .opacityOnBackground {
    background-blend-mode: multiply;
    background-color: #021828;
  }

  // sm	640px	@media (min-width: 640px) { ... }
  // md	768px	@media (min-width: 768px) { ... }
  // lg	1024px	@media (min-width: 1024px) { ... }
  // xl	1280px	@media (min-width: 1280px) { ... }
  // 2xl	1536px	@media (min-width: 1536px) { ... }
  .select {
    height: 45px;
  }

  .animate-download {
    // animation: download 1s ease-in-out forwards;
    animation-name: download;
    animation-timing-function: ease-in-out;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  @keyframes download {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(2px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .socialLink {
    span {
      transition: 0.5s;
      border-radius: 1000px;
      vertical-align: middle;
      @apply text-white bg-blue-darkest;
      &:hover {
        @apply bg-blue-light;
      }
      svg {
        width: 60%;
        height: 60%;
      }
    }
  }
  .bg-tab-title {
    z-index: 1;
    position: relative;
    background-color: var(--blue-darkest);
  }
  .bg-tab-title::after {
    content: "";
    position: absolute;
    top: 0;
    left: calc(100%);
    width: 10px;
    height: calc(100%);
    z-index: 0;
    background: linear-gradient(220deg, transparent 20%, var(--blue-darkest) 20.1%);
  }
}

/* Utilities */
@layer utilities {
  .title-h1 {
    @apply text-[36px] md:text-[40px] xl:text-[48px];
    @apply 2xl:text-[52px];
    @apply leading-[1.15];
  }
  .title-h2 {
    @apply text-[32px] md:text-[34px] xl:text-[36px];
    @apply 2xl:text-[40px];
    @apply leading-[1.15];
  }
  .title-h3 {
    @apply text-[28px] md:text-[30px] xl:text-[32px];
    @apply 2xl:text-[34px] leading-[1.15];
  }
  .title-h4 {
    @apply text-[24px] md:text-[26px] xl:text-[28px];
    @apply 2xl:text-[30px] leading-[1.15];
  }
  .title-h5 {
    @apply text-[18px] md:text-[20px] xl:text-[22px];
    @apply 2xl:text-[24px] leading-[1.27];
  }
  .title-h6 {
    @apply text-[16px] md:text-[18px] xl:text-[20px];
    @apply 2xl:text-[20px] leading-[1.15];
  }
  .text-4xs {
    @apply text-[4px] xl:text-[6px];
    @apply 2xl:text-[8px];
  }
  .text-3xs {
    @apply text-[6px] xl:text-[8px];
    @apply 2xl:text-[10px];
  }
  .text-2xs {
    @apply text-[8px] xl:text-[10px];
    @apply 2xl:text-[12px];
  }
  .text-xs {
    @apply text-[10px] xl:text-[12px];
    @apply 2xl:text-[14px];
  }
  .text-sm {
    @apply text-[14px] 2xl:text-[16px];
  }
  .text-ssm {
    @apply text-[14px] 2xl:text-[8px];
  }
  .text-base {
    @apply text-[16px] 2xl:text-[18px];
  }
  .text-lg {
    @apply text-[16px] md:text-[18px] 2xl:text-[20px];
  }
  .text-xl {
    @apply text-[18px] md:text-[20px] 2xl:text-[24px];
  }
  .text-2xl {
    @apply text-[20px] md:text-[22px] lg:text-[26px];
  }
  .text-3xl {
    @apply text-[24px] md:text-[26px] lg:text-[30px] 2xl:text-[32px];
  }
  .text-4xl {
    @apply text-[32px] md:text-[34px] lg:text-[38px] 2xl:text-[42px];
  }
  .text-5xl {
    @apply text-[36px] md:text-[38px] lg:text-[42px] 2xl:text-[52px];
  }
  .text-6xl {
    @apply text-[60px] md:text-[64px] lg:text-[66px] 2xl:text-[70px];
  }
  .text-7xl {
    @apply text-[65px] md:text-[68px] lg:text-[70px] 2xl:text-[75px];
  }
  .text-8xl {
    @apply text-[80px] md:text-[84px] lg:text-[88px] 2xl:text-[90px];
  }
  .text-9xl {
    @apply text-[100px] md:text-[104px] lg:text-[108px] 2xl:text-[110px];
  }

  .link-primary {
    @apply text-blue-dark underline decoration-transparent hover:decoration-blue-dark underline-offset-2 duration-300;
  }
  .leading-1 {
    line-height: 1 !important;
  }
}
.top-shadow {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

/* Hr */
.blueLine {
  margin-top: 0.5rem;
  width: 300px;
  border: none;
  border-top: 4px solid var(--blue-light);
}

.grayLine {
  margin: 1.5rem auto;
  width: 100%;
  border: none;
  border-top: 1px solid var(--gray-medium);
}

.blueDash {
  content: "";
  display: inline-block;
  width: 7.5px;
  margin-bottom: 6px;
  height: 2.5px;
  background-color: var(--blue-light);
}

.borderRight {
  border-right: 1px solid var(--gray-medium);
}

.strongBorderLeft {
  border-left: 15px solid var(--gray-medium);
  padding: 0 1rem;
}

.border25 {
  border-radius: 50px;
}

@media only screen and (max-width: 575px) {
  .borderRight {
    border-right: none;
    border-bottom: 1px solid var(--gray-medium);
  }
}

/* Containers */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 0;
}

.fullContainer {
  width: 100%;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
}

.flex {
  display: flex;
}

.fullHeightContainer {
  height: 100vh;
}

.displayBlock {
  display: block;
}

.cursorPointer {
  cursor: pointer;
}

/* The "colN" containers are based in a twelve column system, each column represents 8.15 percent */
.col2 {
  width: 16.3%;
}

.col3 {
  width: 23.5%;
}

.col4 {
  width: 32%;
}

.col4-5 {
  width: 35%;
}

.col5 {
  width: 40.75%;
}

.col6 {
  width: 48.9%;
}

.col7 {
  width: 57.05%;
}

.col8 {
  width: 65.2%;
}

.col9 {
  width: 74.2%;
}

.col10 {
  width: 81.5%;
}

.col11 {
  width: 89.65%;
}

.col12 {
  width: 100%;
}

.w40 {
  width: 40%;
}

.w60 {
  width: 60%;
}

@media only screen and (max-width: 575px) {
  .col2,
  .col3,
  .col4,
  .col5,
  .col6,
  .col7,
  .col8,
  .col9,
  .col10,
  .col11 {
    width: 100%;
  }

  .fullMobile {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col2,
  .col3,
  .col4,
  .col5 {
    width: 48.9%;
  }

  .col6,
  .col7,
  .col9,
  .col8,
  .col10,
  .col11 {
    width: 100%;
  }

  .fullMobile {
    width: 100%;
  }

  .fullMobileLandscape {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .col2,
  .col3 {
    width: 32%;
  }

  .col4,
  .col5 {
    width: 48.9%;
  }

  .col7,
  .col8,
  .col9,
  .col10,
  .col11 {
    width: 100%;
  }

  .col6Tablet {
    width: 48.9%;
  }

  .fullTablet {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    max-width: 90%;
  }
}

.darkestBlueBg {
  background-color: var(--blue-darkest);
}

.darkBlueBg {
  background-color: var(--blue-dark);
}

.mediumBlueBg {
  background-color: var(--blue-medium);
}

.blueBg {
  background-color: var(--blue);
}

.lightBlueBg {
  background-color: var(--blue-light);
}

.darkestGrayBg {
  background-color: var(--gray-darkest);
}

.darkGrayBg {
  background-color: var(--gray-dark);
}

.grayBg {
  background-color: var(--gray);
}

.mediumGrayBg {
  background-color: var(--gray-medium);
}

.lightGrayBg {
  background-color: var(--gray-light);
}

.lightestGrayBg {
  background-color: var(--gray-lightest);
}

.whiteBg {
  background-color: #fff;
}

.darkPurpleBg {
  background-color: var(--purple-dark);
}

.mediumPurpleBg {
  background-color: var(--purple-medium);
}

.purpleBg {
  background-color: var(--purple);
}

.magentaBg {
  background-color: var(--magenta);
}

.greenBg {
  background-color: var(--green);
}

.darkGreenBg {
  background-color: var(--green-dark);
}

@media only screen and (max-width: 767px) {
  .whiteAndGray,
  .grayAndWhite {
    background: transparent;
  }
}

/* Distribution */
.spaceAround {
  justify-content: space-around;
}

.spaceBetween {
  justify-content: space-between;
}

.spaceEvenly {
  justify-content: space-evenly;
}

.justifyCenter {
  justify-content: center;
}

.alignCenter {
  align-items: center;
}

.stetchItems {
  align-items: stretch;
}

.wrapReverse {
  flex-wrap: wrap-reverse;
}

/* Spacing */
.mAuto {
  margin: 0 auto !important;
}

.mtLess1 {
  margin-top: -1rem;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mrHalf {
  margin-right: 0.5rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mhHalf {
  margin: 0.5rem 0 !important;
}

.mlHalf {
  margin-left: 0.5rem;
}

.ml1 {
  margin-left: 1rem;
}

.mbHalf {
  margin-bottom: 0.5rem;
}

.mOnButtons button {
  margin: 0.3rem;
}

.ph1 {
  padding: 1rem 0;
}

.ph2 {
  padding: 2rem 0;
}

.ph3 {
  padding: 3rem 0;
}

.p0 {
  padding: 0;
}

.p1 {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.p3 {
  padding: 3rem;
}

.pl1 {
  padding-left: 1rem;
}

.pr1 {
  padding-right: 1rem;
}

@media only screen and (max-width: 575px) {
  .mt1Mobile {
    margin-top: 1rem;
  }

  .mb1Mobile {
    margin-bottom: 1rem;
  }

  .mt-2Mobile {
    margin-top: -2rem;
  }

  .mb-2Mobile {
    margin-bottom: -2rem;
  }

  .mb-4Mobile {
    margin-bottom: -4rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mt1Landscape {
    margin-top: 1rem;
  }

  .mb1Landscape {
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mt1Tablet {
    margin-top: 1rem;
  }
}

/* Text Styles */
.biggestTitle {
  font-size: 75px;
}

.biggerTitle {
  font-size: clamp(1rem, 2rem + 2vw, 3.5rem);
}

.bigTitle {
  font-size: 45px;
}

.mediumTitle {
  font-size: 35px;
}

.smallTitle {
  font-size: 30px;
}

.bigText {
  font-size: 25px;
  line-height: 1.3;
}

.mediumText {
  font-size: 20px;
  line-height: 1.25;
}

.regularText {
  font-size: 17px;
}

.smallText {
  font-size: 14px;
}

.xsText {
  font-size: 11px !important;
}

.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.fw600 {
  font-weight: 600;
}

.fw500 {
  font-weight: 500;
}

.fw400 {
  font-weight: 400;
}

.lh17 {
  line-height: 1.7;
}

.lh15 {
  line-height: 1.5;
}

.lh12 {
  line-height: 1.2;
}

.lineHeight17 {
  line-height: 1.7;
}

@media only screen and (max-width: 575px) {
  .biggestTitle {
    font-size: 55px;
  }

  .bigTitle {
    font-size: 32.5px;
  }

  .mediumTextMobile {
    font-size: 18px;
  }

  .mediumTitle {
    font-size: 27.5px;
  }

  .smallTitle {
    font-size: 25px;
  }

  .tacMobile {
    text-align: center;
  }

  .mediumTitleMobile {
    font-size: 25px;
  }

  .smallTitleMobile {
    font-size: 20px;
  }

  .talMobile {
    text-align: left;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .biggestTitle {
    font-size: 65px;
  }

  .bigTitle {
    font-size: 37.5px;
  }

  .mediumTitle {
    font-size: 27.5px;
  }

  .smallTitle {
    font-size: 25px;
  }

  .bigText {
    font-size: 22.5px;
  }

  .tacMobile {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .bigTitle {
    font-size: 40px;
  }

  .mediumTitle {
    font-size: 30px;
  }

  .smallTitle {
    font-size: 27.5px;
  }
}

/* List styles */
.discList li::before {
  content: "•";
  font-size: 40px;
  line-height: 0;
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.blueDiscList li::before {
  content: "•";
  color: var(--blue);
  font-size: 40px;
  line-height: 0;
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.blueDot::before {
  content: "•";
  color: var(--blue);
  font-size: 40px;
  line-height: 0;
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.checkListIcon {
  position: relative;
  top: 3px;
  width: 17px;
}

.checkBoldListIcon {
  position: relative;
  top: 0;
  width: 17px;
}

/* Text Colors */
.white {
  color: #fff;
}

.whiteText {
  color: #fff !important;
}

.darkestBlue {
  color: var(--blue-darkest);
}

.darkBlue {
  color: var(--blue-dark);
}

.mediumBlue {
  color: var(--blue-medium);
}

.blue {
  color: var(--blue);
}

.lightBlue {
  color: var(--blue-light);
}

.darkestGray {
  color: var(--gray-darkest);
}

.darkGray {
  color: var(--gray-dark);
}

.gray {
  color: var(--gray);
}

.mediumGray {
  color: var(--gray-medium);
}

.lightGray {
  color: var(--gray-light);
}

.lightestGray {
  color: var(--gray-lightest);
}

.darkPurple {
  color: var(--purple-dark);
}

.mediumPurple {
  color: var(--purple-medium);
}

.purple {
  color: var(--purple);
}

.magenta {
  color: var(--magenta);
}

.green {
  color: var(--green);
}

.darkGreen {
  color: var(--green-dark);
}

/* Display */
.hide {
  display: none !important;
}

.hideDesktop {
  display: none;
}

@media only screen and (max-width: 767px) {
  .showMobile {
    display: block !important;
  }

  .hideMobile {
    display: none !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hideLandscape {
    background-color: red;
    height: 0;
  }
}

/* Floating */
.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.clearFloat {
  clear: both;
}

/* Special */
.iconForLink {
  width: 15px;
  margin-left: 5px;
}

/* Images */
.fullWidthImage {
  width: 100%;
  height: auto;
}

.fade-appear {
  opacity: 0;
  animation: fadeIn 0.3s cubic-bezier(0.88, 0.1, 0.62, 0.97) forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cascade-animation-delay-100 {
  @for $i from 1 through 10 {
    :nth-child(#{$i}) {
      animation-delay: #{$i * 100}ms;
    }
  }
}

.consultantImage {
  width: 200px;
  height: 200px;
  border-radius: 500px;
  object-fit: cover;
  border: 7.5px solid #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.consultantImage img {
  border-radius: 500px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.specialBtn {
  background-color: #1589cb !important;
}

.specialBtn:hover {
  background-color: #0d71ac !important;
}

.topPennant {
  background-color: rgba(26, 26, 26, 0.74);
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 0.75rem;
}

.iconMini {
  width: 18px;
  height: 18px;
}

.hoverToBlue {
  transition: 0.5s;
}

.hoverToBlue:hover {
  color: var(--blue);
}

.mainPageRectangleLogoImage {
  height: auto;
  width: auto;
  max-height: 190px;
  max-width: 250px;
}

.mainPageSquareLogoImage {
  height: auto;
  width: auto;
  max-height: 140px;
  max-width: 250px;
}

.mainPageBigSquareLogoImage {
  height: auto;
  width: auto;
  max-height: 160px;
  max-width: 250px;
}

.boxShadow {
  box-shadow: var(--box-shadow);
}

.blueBtn {
  padding: 0.5rem 1rem;
  background-color: var(--blue-darkest);
  border-radius: 10px;
  transition: 0.3s;
}

.blueBtn:hover {
  background-color: var(--blue-light);
}

/* Styles for to change the reCAPTCHA badge position */
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: -2px !important;
  bottom: 20px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

.pending { /* Pending state because of content pending to be loaded */
  @apply border-red-500 border-2;
  :after {
    content: "Pending";
    @apply text-red-500;
  }
}
